@import 'theme/globals';

body button {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

a#mailto {
  color: $red;

  &:hover {
    text-decoration: $red underline !important;
  }
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

object.svg-element {
  color: aliceblue;
}
object.svg-element path {
  fill: aliceblue;
}

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

div[data-baseweb='popover'] {
  z-index: 10000;
}

body .rounded-topleft {
  border-top-left-radius: 40px;
}

body .rounded-bottomright {
  border-bottom-right-radius: 40px;
}

/**
Cookiebot
 */
a#CybotCookiebotDialogDetailBodyContentCookieContainerPreference {
  display: none;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  background-color: #fff !important;
  border: 1px solid #d3d3d3 !important;
  color: #e20613 !important;
  padding: 7px !important;
  border-radius: 5px;
}
a:hover#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  color: #e20613 !important;
  padding: 7px !important;
  border-radius: 5px;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  padding: 7px !important;
  border-radius: 5px;
}
#CybotCookiebotDialog {
  border: 18px solid #fff !important;
  max-width: 730px !important;
}
