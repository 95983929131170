@import 'variables';
@import 'typography';
@import 'mixins/screenSize';
@import 'fonts';

:root {
  box-sizing: border-box;

  // Assumption for all screen sizes when usem rem values.
  // Don't actually set it here, as the user styles might want to set it.
  // font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Univers LT Pro';
  font-weight: normal;
  color: $darkGrey; // same as "copy" text
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
