@import 'mixins/screenSize';

h1,
h2,
h3,
h4 {
  font-weight: bold;
  color: $black;
}

h1.stage {
  font-size: 2.375rem; // 2.375rem = 38px based on 16px font size
  line-height: 3rem; // 3rem = 48px based on 16px font size
}

h1 {
  font-size: 2rem; // 2rem = 32px based on 16px font size
  line-height: 2.5rem; //  2.5rem = 40px based on 16px font size
}

h2 {
  font-size: 1.625rem; // 1.625rem = 26px based on 16px font size
  line-height: 2rem; //  2rem = 32px based on 16px font size
}

h3 {
  font-size: 1.375rem; // 1.375rem = 22px on 16px font size
  line-height: 2rem; // 2rem = 32px based on 16px font size
}

h4 {
  font-size: 1.25rem; // 1.25rem = 20px based on 16px font size
  line-height: 2rem; // 2rem = 32px based on 16px font size
}

.copy-xl {
  font-size: 1.25rem; // 1.25rem = 20px based on 16px font size
  line-height: 2rem; // 2rem = 32px based on 16px font size
  color: $darkGrey;
}

.copy-l {
  font-size: 1.125rem; // 1.125rem = 18px based on 16px font size
  line-height: 1.5rem; //  1.5rem = 24px based on 16px font size
  color: $darkGrey;
}

.copy {
  font-size: 1rem;
  line-height: 1.5rem; //  1.5rem = 24px based on 16px font size
  color: $darkGrey;
}

.copy-s {
  font-size: 0.875rem; // 0.875rem = 14px based on 16px font size
  line-height: 1.25rem; // 1.25rem = 20px based on 16px font size
  color: $darkGrey;
}

.tag-line {
  font-size: 0.875rem; // 0.875rem = 14px based on 16px font size
  font-weight: bold;
  line-height: 1.5rem; //  1.5rem = 24px based on 16px font size
  color: $black;
}

// Style guide calls them "Viewport L" which is actually md
@include screenSize('md') {
  h1.stage {
    font-size: 3.875rem; // 3.875rem = 62px based on 16px font size
    line-height: 5rem; // 5rem = 80px based on 16px font size
  }

  h1 {
    font-size: 3.125rem; // 3.125rem = 50px based on 16px font size
    line-height: 4rem; //  4rem = 64px based on 16px font size
  }

  h2 {
    font-size: 2rem; // 2rem = 32px based on 16px font size
    line-height: 3rem; //  3rem = 48px based on 16px font size
  }

  h3 {
    font-size: 1.75rem; // 1.75rem = 28px based on 16px font size
    line-height: 2.5rem; // 2.5rem = 40px based on 16px font size
  }

  h4 {
    font-size: 1.75rem; // 1.5rem = 24px based on 16px font size
    line-height: 2.5rem; // 2rem = 32px based on 16px font size
  }

  .copy-xl {
    font-size: 1.5rem; // 1.5rem = 24px based on 16px font size
    line-height: 2rem; // 2rem = 32px based on 16px font size
    color: $darkGrey;
  }

  .copy-l {
    font-size: 1.375rem; // 1.25rem = 20px based on 16px font size
    line-height: 2rem; // 2rem = 32px based on 16px font size
    color: $darkGrey;
  }

  .copy {
    font-size: 1rem;
    line-height: 1.5rem; //  1.5rem = 24px based on 16px font size
    color: $darkGrey;
  }

  .copy-s {
    font-size: 1rem;
    line-height: 1.5rem; // 1.5rem = 24px based on 16px font size
    color: $darkGrey;
  }

  .tag-line {
    font-size: 0.875rem; // 0.875rem = 14px based on 16px font size
    font-weight: bold;
    line-height: 1.5rem; //  1.5rem = 24px based on 16px font size
    color: $black;
  }
}

.text-left {
  text-align: left;
}
body {
  .text-sm-left {
    @include screenSize('sm') {
      text-align: left;
    }
  }
  .text-md-left {
    @include screenSize('md') {
      text-align: left;
    }
  }
  .text-lg-left {
    @include screenSize('lg') {
      text-align: left;
    }
  }
  .text-xl-left {
    @include screenSize('xl') {
      text-align: left;
    }
  }
  .text-xxl-left {
    @include screenSize('xxl') {
      text-align: left;
    }
  }
}
.text-center {
  text-align: center;
}
body {
  .text-sm-center {
    @include screenSize('sm') {
      text-align: center;
    }
  }
  .text-md-center {
    @include screenSize('md') {
      text-align: center;
    }
  }
  .text-lg-center {
    @include screenSize('lg') {
      text-align: center;
    }
  }
  .text-xl-center {
    @include screenSize('xl') {
      text-align: center;
    }
  }
  .text-xxl-center {
    @include screenSize('xxl') {
      text-align: center;
    }
  }
}

.text-right {
  text-align: right;
}
body {
  .text-sm-right {
    @include screenSize('sm') {
      text-align: right;
    }
  }
  .text-md-right {
    @include screenSize('md') {
      text-align: right;
    }
  }
  .text-lg-right {
    @include screenSize('lg') {
      text-align: right;
    }
  }
  .text-xl-right {
    @include screenSize('xl') {
      text-align: right;
    }
  }
  .text-xxl-right {
    @include screenSize('xxl') {
      text-align: right;
    }
  }
}

.text-bold {
  font-weight: bold;
}
