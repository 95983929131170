// keep in sync with the values used with styletron!

$breakpoints: (
  // "xs": <576px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px
);

// primary colors
$red: #e20613;
$white: #ffffff;
$lightGrey: #f3f3f3;
$midGrey: #b2b2b1;
$darkGrey: #313131;
$black: #000000;
// secondary colors
$marine: #00344e; // a dark blue
$water: #009bb4; // a light blue
$meadow: #417232; // a green
$sunflower: #f7a60a; // a yellow
$midRed: #c90613; // an intense red
$darkRed: #b00511; // less intense red

.clr-red {
  color: $red !important;
}
.clr-white {
  color: $white !important;
}
.clr-light-grey {
  color: $lightGrey !important;
}
.clr-mid-grey {
  color: $midGrey !important;
}
.clr-dark-grey {
  color: $darkGrey !important;
}
.clr-black {
  color: $black !important;
}
.clr-marine {
  color: $marine !important;
}
.clr-water {
  color: $water !important;
}
.clr-meadow {
  color: $meadow !important;
}
.clr-sunflower {
  color: $sunflower !important;
}
.clr-mid-red {
  color: $midRed !important;
}
.clr-dark-red {
  color: $darkRed !important;
}
