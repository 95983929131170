@font-face {
  font-family: 'Univers LT Pro';
  src: url('../../assets/fonts/UniversLTW02-45Light.woff2') format('woff2'),
    url('../../assets/fonts/UniversLTW02-45Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Univers LT Pro';
  src: url('../../assets/fonts/UniversLTW02-45LightOblique.woff2') format('woff2'),
    url('../../assets/fonts/UniversLTW02-45LightOblique.woff') format('woff');
  font-weight: 200;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: 'Univers LT Pro';
  src: url('../../assets/fonts/UniversLTW02-55Roman.woff2') format('woff2'),
    url('../../assets/fonts/UniversLTW02-55Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Univers LT Pro';
  src: url('../../assets/fonts/UniversLTW02-55Oblique.woff2') format('woff2'),
    url('../../assets/fonts/UniversLTW02-55Oblique.woff') format('woff');
  font-weight: 400;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: 'Univers LT Pro';
  src: url('../../assets/fonts/UniversLTW02-65Bold.woff2') format('woff2'),
    url('../../assets/fonts/UniversLTW02-65Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Univers LT Pro';
  src: url('../../assets/fonts/UniversLTW02-65BoldOblique.woff2') format('woff2'),
    url('../../assets/fonts/UniversLTW02-65BoldOblique.woff') format('woff');
  font-weight: 600;
  font-style: oblique;
  font-display: swap;
}
