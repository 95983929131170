@mixin screenSize($screenSize) {
  @if map-has-key($breakpoints, $screenSize) {
    @media screen and (min-width: #{map-get($breakpoints, $screenSize)}) {
      @content;
    }
  }
}

@mixin screenSize-upto($screenSize) {
  @if map-has-key($breakpoints, $screenSize) {
    @media screen and (max-width: #{map-get($breakpoints, $screenSize)}) {
      @content;
    }
  }
}
